$(document).ready(function () {
    $('body#duplicates .js-family-lookup').blur(function (e) {
        const $familyLookupResult = $(this).closest('.form-group').find('.js-family-lookup-result');
        const familyId = e.target.value;
        $.getJSON('/api/family/' + familyId)
            .done(function(response) {
                $familyLookupResult.text(response.family_name);
            })
            .fail(function() {
                $familyLookupResult.text('No such Family');
            });
    });
});
