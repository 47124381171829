import * as Sentry from '@sentry/browser';

const environment = getSentryEnvironment();
if (environment) {
    Sentry.init({
        dsn: 'https://d457a49c21b149fd85b8d28bf8af2b00@sentry.io/109638',
        release: import.meta.env.VITE_RELEASE,
        environment: environment,
    });
}

function getSentryEnvironment() {
    if (window.location.hostname === 'checkin.hillsong.se') {
        return 'production';
    }
    if (window.location.hostname === 'checkin-staging.hmazter.dev') {
        return 'staging';
    }
    return null;
}

// export Sentry functions to use in window
window.captureException = Sentry.captureException;
