import $ from 'jquery';
import * as Growl from './growl';
import Swal from 'sweetalert2';

$(function () {
    'use strict'

    $('[data-toggle="offcanvas"]').on('click', function () {
        $('.offcanvas-collapse').toggleClass('open')
    })

    $('[data-toggle="popover"]').popover();
});

document.addEventListener('livewire:init', () => {
    window.Livewire.on('toast', (event) => {
        Growl.message(event.message, event.type);
    });

    window.Livewire.on('swal', (event) => {
        Swal.fire({
            title: event.title,
            html: event.content,
        });
    });
});
