import $ from 'jquery';
window.$ = window.jQuery = $;

(function () {
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': window.Laravel.csrfToken,
            'Authorization': 'Bearer ' + window.Laravel.api_token
        }
    });
})();
