/**
 * @param {string} event
 * @param data
 */
export const emit = (event, data) => {
    window.dispatchEvent(new CustomEvent(event, {detail: data}))
};

/**
 * @param {string} event
 * @param {function} callback
 */
export const on = (event, callback) => {
    window.addEventListener(event, payload => callback(payload.detail));
};
