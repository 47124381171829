// Mobile Safari in standalone mode
if ("standalone" in window.navigator && window.navigator.standalone) {
    let node;
    document.addEventListener('click', function (event) {
        node = event.target;
        // Bubble up until we hit link or top HTML element. Warning: BODY element is not compulsory so better to stop on HTML
        while (node && node.nodeName !== "A" && node.nodeName !== "HTML") {
            node = node.parentNode;
        }

        if (node && 'href' in node && node.href.indexOf('http') !== -1 && node.href.indexOf(document.location.host) !== -1) {
            event.preventDefault();
            document.location.href = node.href;
        }
    });
}
